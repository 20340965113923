import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#0590B2';
const footerRelatedLinks = [
	{ title: 'Debiopharm', url: '/fr/projets/debiopharm/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/avriontx/avrion_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/avriontx/avrion_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/avriontx/avrion_brand_dna.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/avriontx/avrion_2.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: { eq: "projects/avriontx/avrion_3.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/avriontx/avrion_4.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/avriontx/avrion_5.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/avriontx/avrion_6.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/avriontx/avrion_7.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: { eq: "projects/avriontx/avrion_8.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/avriontx/avrion_9.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/avriontx/avrion_10.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: { eq: "projects/avriontx/avrion_11.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/avrion-therapeutics-branding/',
					lang: 'en',
				},
			]}
			title="Avrion Therapeutics Branding - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Avrion Therapeutics"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Restaurer des vies et apporter un espoir aux
							personnes atteintes de maladies neuro-dégénératives
							(ALS)
						</p>

						<p className="p-small">
							Avrion Therapeutics développe un traitement contre
							la sclérose latérale amyotrophique (SLA), maladie
							neurodégénérative sans traitement qui conduit au
							décès du patient dans un délai de 3 à 5 ans.{' '}
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Positonnement de marque',
								'Branding',
								'Identité visuelle',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Dans l’environnement très pointu des startups
							biotechnologiques ciblant l’ADN, cette spin-off de
							l’EPFL se démarque par ses qualités de précision et
							de simplicité technologique.{' '}
						</p>

						<h3>Notre solution</h3>
						<p>
							Notre travail de marque a eu la volonté de sortir
							des sentiers classiques connus, de s’éloigner des
							symboliques utilisées par les concurrents, tout en
							s’appuyant sur une approche graphique minimaliste.
						</p>
						<p>
							Nous avons choisi de mettre le focus sur l’humain et
							ses histoires de vie plutôt que de nous attacher
							directement à la technologie. Derrière chaque
							personne atteinte de la maladie, il y a un ADN, une
							vie à la recherche de l’espoir de pouvoir un jour
							revivre normalement.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>Brand DNA</h3>
							<p>
								Nos workshops de marque ont permis d’identifier
								les fondamentaux d’Avrion et de les traduire en
								une marque forte, cohérente et adaptée à un
								important potentiel de croissance.
							</p>
						</div>
						<Image {...data.image_1.childImageSharp} alt="Logo" />
					</div>
				</div>

				<Video
					className="col-full"
					src="d979a2eeca219b93a972ddf6770e97d2"
					alt="Animation de l'ADN au symbole"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote={
						<>
							Et si ce qui est source de maladie devenait source
							d’espoir et de vie&nbsp;?
						</>
					}
					more="Avrion Therapeutics cible précisément la partie non fonctionnelle de l’ADN en ayant pour objectif de la restaurer. Il nous a paru évident de traduire cette séquence par un visage calme et souriant; plein de vie et capable de nous regarder droit dans les yeux, comme son futur."
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Trame visuelle de la marque"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Symbole du sourire sur fond turquoise"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_2.childImageSharp}
					alt="Typography"
				/>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image
							{...data.image_5.childImageSharp}
							alt="Symbole du sourire sur fond turquoise"
						/>
						<Image
							{...data.image_10.childImageSharp}
							alt="Symbole du sourire sur fond turquoise"
						/>
						<Image
							{...data.image_6.childImageSharp}
							alt="Symbole du sourire sur fond turquoise"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							Le branding d'Avrion est une interprétation forte de
							la tension de la marque.{' '}
						</h3>
						<p>
							A la fois audacieuse par son sourire mais aussi
							véritablement pragmatique par sa simplicité et sa
							démarche graphique, cette marque est centrée sur
							l'humain et sur la restauration. La simplicité du
							symbole bénéficie non seulement d'une reconnaissance
							directe, mais aussi d'une facilité d'intégration
							dans différents contextes.
						</p>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_8.childImageSharp}
					alt="Visuel d'une carte de visite"
				/>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							La simplicité du symbole bénéficie d'une
							reconnaissance directe.{' '}
						</h3>
						<p>
							Mais aussi d'une facilité d'intégration dans
							différents contextes potentiels.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_7.childImageSharp}
							alt="Photo d'un médecin"
						/>
						<Image
							{...data.image_9.childImageSharp}
							alt="Photo d'une fiole"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_11.childImageSharp}
					alt="Visuel d'une interface internet"
				/>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
